module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!title) var title = '';
if (!desc) var desc = '';
;
__p += '\r\n<div class="scroll-icon"></div>\r\n<section class="nybanner1 container">\r\n  <!-- <div class="bg">\r\n    <video muted autoplay loop poster="' +
((__t = (require('../../assets/images/page_bg1.jpg'))) == null ? '' : __t) +
'" src="https://icelandtravel.oss-cn-beijing.aliyuncs.com/black.mp4" x5-video-player-type="h5" x5-video-player-fullscreen="true" webkit-playsinline="" playsinline=""></video>\r\n  </div> -->\r\n  <div class="text">\r\n    <h1>' +
((__t = ( title )) == null ? '' : __t) +
'</h1>\r\n    <h2>' +
((__t = ( desc )) == null ? '' : __t) +
'</h2>\r\n  </div>\r\n</section>';

}
return __p
}