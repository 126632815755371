module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!title) var title = '';
if (!desc) var desc = '';
;
__p += '\r\n<section class="nybanner2 container">\r\n  <div class="t">\r\n    <h2>' +
((__t = ( desc )) == null ? '' : __t) +
'</h2>\r\n    <h1>' +
((__t = ( title )) == null ? '' : __t) +
'</h1>\r\n  </div>\r\n  <div class="big-line is-inview"><b></b><b></b><b></b><b></b><b></b><b></b><b></b><b></b><b></b></div>\r\n</section>';

}
return __p
}